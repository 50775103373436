@import "~sharedFromCoreComponentsCss";
@import "~sharedFromCoreComponents";

@mixin centredArrow {

}

.rc-tooltip {
  max-width: 400px;

  @media (max-width: 450px) {
    max-width: 100%;
    left: 0!important;
  }
}

.rc-tooltip-inner {
  background-color: #FFFFFF;
  border: 1px solid #9A9FA6;
  box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.25);
  color: #1A2026;
  padding: 24px;

  h2 {
    font-size: 21px;
    line-height: 125%;
    color: #69717A;
    margin: 0 0 16px;
  }

  p{
    font-size: 14px;
    line-height: 125%;
    color: #1A2026;
    margin: 0;
  }
}

.rc-tooltip-placement-right .rc-tooltip-arrow, .rc-tooltip-placement-rightTop .rc-tooltip-arrow, .rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  background-color: #FFFFFF;
  border: 1px solid #9A9FA6;
  box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.25);
  width: 25px;
  height: 25px;
  transform: rotate(45deg) translateX(-10px) translateY(-1px);

  &:after {
    z-index: 20;
    background: white;
    height: 35px;
    width: 22px;
    position: absolute;
    content: " ";
    left: 8px;
    top: -14px;
    transform: rotate(-45deg);
  }
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow, .rc-tooltip-placement-bottomLeft .rc-tooltip-arrow, .rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  background-color: #FFFFFF;
  border: 1px solid #9A9FA6;
  box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.25);
  width: 25px;
  height: 25px;
  transform: rotate(135deg) translateX(-2px) translateY(8px);
  left: 50%;
  right: 0;

  &:after {
    z-index: 20;
    background: white;
    height: 35px;
    width: 20px;
    position: absolute;
    content: " ";
    left: 9px;
    top: -13px;
    transform: rotate(-45deg);
  }
}
