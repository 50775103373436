:local {
    .AddToShoppingCartButton {
        button {
            width: 100%;
            white-space: nowrap;
            height: 40px;
        }
    }

    .AddToShoppingCartButtonInCart {
        button {
            color: white;
            background: #00ca9d;
            border: 1px solid #00ca9d;
            padding-left: 12px;
            padding-right: 12px;

            svg {
                path {
                    fill: #ffffff;
                }
            }

            &:active {
                background: #00ca9d;
                border: 1px solid #00ca9d;
            }

            &:hover {
                @media (hover: hover) and (pointer: fine) {
                    background: #a22100;
                    border-color: #a22100;
                }

                @media (hover: none) and (pointer: coarse) {
                    background: #00ca9d;
                    border: 1px solid #00ca9d;
                }
            }
        }
    }
}
