$font-family-sans-serif:  'ProximaNova' , Helvetica,Arial,sans-serif; // "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-base:        $font-family-sans-serif !default;
$font-size-base:          14px !default;
$line-height-base:        1.428571429 !default; // 20/14

$body-bg: #fff;
$text-color: #333;
$link-color: #2f95c7;
$link-hover-color: #2f95c7;
$link-hover-decoration: default;
$brand-primary:        #881155;

$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 1200px;
$screen-lg: 1380px;

// So media queries don't overlap when required, provide a maximum
$screen-srp-mobile-max:              750px;
$screen-srp-web-min:                 751px;
$screen-xs-max:              ($screen-sm - 1) !default;
$screen-sm-max:              ($screen-md - 1) !default;
$screen-md-max:              ($screen-lg - 1) !default;

/* Wedding Spot custom variables */
$z-index-header: 10;
$z-index-modals: 100;
