/**
 * Color variables.
 * Note about naming conventions: *-50 is the most common variant
 * of the color, subsequent darker versions are named by adding 10, i.e. *-60, *-70, etc.
 * In theory *-100 would be black and *-0 would be white, but it's not really an exact
 * measure.
 */

// Brand colors.
$magenta: #881155;
$nx-magenta-50: #881155;
$nx-magenta-60: #6E004B;

// Text and Backgrounds

$grey-30: #AAA;
$grey-40: #767b84;
$grey-50: #F7F7F7;

$grey-80: #A8ADB8;

$charcoal-50: #24272B;

$offred-50: #FF6161;

$green-80: #155724;







$color-text: $charcoal-50 !default;






$color-brand-primary: $nx-magenta-50 !default;

$color-text-light: $grey-40 !default;
$color-text-extra-light: $grey-30 !default;

$color-text-danger: $offred-50 !default;
$color-text-success: $green-80 !default;
$color-text-helper: $grey-80 !default;