@import '../../scss/shared.scss';


$screen-sm: 767px; /* TODO: We shouldn't have these application/page-specific
                      breakpoints in components lib. It's better if we design the 
                      components to allow the calling application to specify this */


:local {
    .shoppingCartContainer {
        float: right;
        margin-left: 20px;
        margin-top: 8px;
        position: relative;

        @media all and (max-width: $screen-sm){
            position: absolute;
            right: 0;
            top: 0;
            margin-top: 24px;
            margin-right: 17px;
        }

    }
    .shoppingCartList {
        margin-top: 2px;
        width: 420px;
        max-height: 629px;
        overflow-y: auto;
        position: absolute;
        right: 0;
        background: white;
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.06);
        z-index: 21;

        @media all and (max-width: $screen-sm){
            margin-top: 0;
            position: fixed;
            top: 0;
            width: 100%;
            height: 100%;
            max-height: none;
        }
    }
}