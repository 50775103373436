@import '../../scss/shared.scss';

/* TODO: We shouldn't have these application/page-specific
breakpoints in components lib. It's better if we design the 
components to allow the calling application to specify this */
$screen-xs-max: 765px;
$screen-sm: 768px;
$screen-md: 1277px;


:local .inlineSection {
    display: inline;
}

:local .clearfix {
    @include clearfix;
}

:local .textCenter {
    text-align: center;
}

:local .className {
    @include clearfix;
    position: relative;
    height: 66px;
    border-bottom: 1px solid #cac8c8;
    padding-top: 5px;

    @include nx-medium-up {
        padding: 5px 25px;
    }
}

:local .collapsed {
    height: 0 !important;
    padding: 0 !important;
    border: none !important;
    overflow: hidden !important;
}


:local .logo {
    display: block;
    height: 56px;
    margin: 0 auto;
    float: left;

    @media (max-width: calc(#{$screen-sm} - 1px)) {
        height: 66px;
        float: inherit;
    }
}

:local .hamburger {
    position: absolute;
    padding: 6px 12px;
    top: 13px;
    left: 6px;
    text-decoration: none;
    font-size: 25px;
    color: #881155;

    &:active,
    &:visited,
    &:hover,
    &:focus {
        color: #881155;
    }

    @media (min-width: calc(#{$screen-md} + 1px) ) {
        display: none;
    }
}

:local .sticky {
    @media (min-width: 768px) {
        z-index: 3;
        background-color: white;
    }
}

:local .logoLink {
    display: inline-block;
    height: 56px;
    display: block;

    > img {
        display: block;
        margin: 0 auto;
        max-height: 100%;
    }

    .regularLogo {
        display: block;
    }

    .whiteLogo {
        display: none;
    }

    @media (max-width: $screen-md) {
        margin: 0 19px 0 39px;
    }
}

:local .messagesLinkWrapper {
    position: absolute;
    padding: 19px 12px;
    top: -3px;
    right: 6px;
    text-decoration: none;
    img {
        width: 25px;
    }

    > a {
        color: #893254;
        font-size: 25px;
        color: #881155;

        &:hover,
        &:active,
        &:focus,
        &:visited {
            color: #893254;
        }
    }
}

:local .haveAccount {
    float: right;
    margin-top: 22px;
    margin-right: 47px;
    color: #47505e;
}

:local .link {
    color: #881155;
    &:active,
    &:focus,
    &:hover,
    &:visited {
        color: #881155;
    }
}

:local .messagesBadge {
    display: inline-block;
    min-width: 10px;
    padding: 5px 5px 4px 6px;
    border-radius: 10px;
    
    font-size: 9px;
    font-weight: 700;
    line-height: 10px;
    
    color: #fff;
    background-color: #b94a48;

    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    text-shadow: none;
    font-weight: normal;
}

:local .reviewsBadge {
    display: inline-block;
    min-width: 10px;
    padding: 5px 5px 4px 6px;
    border-radius: 10px;
    
    font-size: 9px;
    font-weight: 700;
    line-height: 10px;
    
    color: #fff;
    background-color: #b94a48;

    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    text-shadow: none;
    font-weight: normal;
}

:local .searchWrapper {
    display: none;
    padding: 12px 30px;
    position: relative;
    float: left;
}

:local .searchInput {
    font-size: 15px;
    font-weight: 300;
    height: 30px;
    width: 280px;
    padding: 4px 4px 4px 30px;
    color: #333;
    &:focus {
        outline: none;
    }
}

:local .searchIcon {
    position: absolute;
    top: 14px;
    left: 35px;
    font-size: 22px;
    color: #881155;
}

:local .navLink {
    font-weight: 400;
    vertical-align: middle;
    text-decoration: none;
    font-size: 15px;
    letter-spacing: 1.1px;
    color: #000;
    padding: 17px 15px;
    display: inline-block;
    position: relative;
    cursor: pointer;

    &:active,
    &:visited {
        outline: none;
        color: #000;
    }

    &:hover,
    &:focus {
        color: #000;
    }

    .messagesBadge {
        position: relative;
        top: -3px;
        margin-right: 5px;
    }

    .reviewsBadge {
        position: relative;
        top: -3px;
        margin-right: 5px;
    }

}

:local .navLinkLocations {
    padding-bottom: 22px;
    i {
        font-size: 10px;
    }
}

:local .hoverMenuWrapper {
    float: left;
}

:local .locationsDropdown {

    display: none;
    position: absolute;
    background: white;
    padding: 20px 10px;
    border-radius: 2px;
    margin-top: 5px;
    width: 1300px;
    box-shadow: #ddd 0px 5px 15px 0px;
    z-index: $z-index-header;
    min-height: 200px;
}

:local .subheaderLinksWrapper {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: $z-index-header;
}

:local .locationsDropdownShowing,
:local .subheaderLinksWrapperShowing {
    display: block;
}


:local .navWrapper {
    float: right;
    /* Add some padding to the left so the subheader doesn't disappear
    if the user moves their mouse too horizontally
    */
    padding-left: 15px;
    @include nx-medium-down {
        padding-left: 10px;
        margin-right: -25px;
    }

    @media (max-width: $screen-md) {
        display: none;
    }
}

:local .navSeparator {
    padding: 0;
    width: 1px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
    opacity: .7;
    background-color: #f0f0f0;
}

:local .dropdownMenu {
    .messagesBadge, .reviewsBadge {
        margin-left: 5px;
    }
}

:local .locationLink {
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 4px 15px;
    &:hover {
        background-color: #eee;
    }
}

:local .authNavWrapper {
    display: inline-block;
}

:local .dropdownWrapper {
    display: inline-block;
    position: relative;
}

:local .dropdownMenu {
    position: absolute;
    padding: 5px 0;
    display: none;
    right: 0;
    width: 200px;
    background-color: #fff;
    z-index: $z-index-header;
    border: 1px solid rgba(0,0,0,.15);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);

    > ul {
        list-style: none;
        margin: 0;
        padding: 0;

        > li > a {
            display: block;
            padding: 3px 20px;
            clear: both;
            font-size: 12px;
            line-height: 1.42857143;
            color: #333;
            white-space: nowrap;

            &:hover {
                background-color: #f5f5f5;
            }
        }

        > li > form > button {
            display: inline-block;
            width:100%;
            text-align: left;
            padding: 3px 20px;
            background: none;
            border: none;
            font-size: 12px;
            line-height: 1.42857143;
            color: #333;
            white-space: nowrap;

            &:hover {
                background-color: #f5f5f5;
            }
        }
    }
}

:local .dropdownMenuOpen {
    display: block;
}

:local .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
}

:local .newBubble {
    border-radius: 100px;
    background-color: rgba(136, 17, 85, 0.14);
    padding: 2px 4px;
    font-size: 10px;
    font-weight: 600;
    color: #881155;
}

:local .userIcon,
:local .messageIcon {
    color: #881155;
    font-size: 20px;
    vertical-align: sub;
}

:local .dropdownIcon {
    font-size: 8px;
    vertical-align: middle;
}

:local .mobileNav {
    position: absolute;
    width: 100%;
    font-size: 20px;
    background-color: #fff;
    top: 100%;
    z-index: $z-index-header;
    overflow: hidden;
    height: 0;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.1);
    border-top: 2px solid #e7e7e7;
    border-bottom: 1px solid #cac8c8;
    left: 0px;

    @media (min-width: calc(#{$screen-md} + 1px)) {
        display: none;
    }
}

:local .mobileNavContentContainer {
    margin: 10px 0 15px 0;
}

:local .mobileNavDivider {
    border-top: solid 1px #eeeff1;
}

:local .mobileNavLink {
    display: block;
    color: #24272B;
    font-size: 20px;
    line-height: 20px;
    padding: 10px 30px;
    text-decoration: none;

    &:active,
    &:visited,
    &:focus,
    &:hover {
        color: #24272B;
    }
}

:local .mobileNavButtonLink {
    background: none;
    border: none;
    display: block;
    color: #24272B;
    font-size: 20px;
    line-height: 20px;
    padding: 10px 30px;

    &:active,
    &:visited,
    &:focus,
    &:hover {
        color: #24272B;
    }
}


:local .mobileNavCollapseIcon {
    font-size: 10px;
    vertical-align: middle;
}

:local .mobileNavSubmenu .mobileNavLink {
    font-size: 18px;
    color: #767b84;
    border: none;
    font-weight: lighter;
    line-height: 30px;
    padding: 10px 0 0 50px;
}

// Responsive styles
@include nx-small-only {
    :local .hiddenMobile {
        display: none;
    }
}

@include nx-medium-up {
    :local .visibleMobile {
        display: none;
    }
    :local .searchWrapper {
        display: inline-block;
    }
}

:local .typeahead {
    padding-top: 14px;
    margin-left: 10px;
    display: inline-block;
    
    @media (max-width: calc(#{$screen-sm} - 1px)) {
        display: none;
    }
}

:local .whiteStyle {
    @media(min-width: $screen-sm) {
        .navLink,
        [class^="icon-hb-"], [class*=" icon-hb-"],
        .hamburger {
            color: white;
        }

        .regularLogo {
            display: none;
        }

        .whiteLogo {
            display: block;
        }
    }
}

:local .readyForSplash {
    @media(min-width: $screen-sm) {
        border-bottom: none;
    }
}

:local .headerItemSeparator {
    padding: 0;
    width: 1px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
    opacity: .7;
    background-color: #f0f0f0;
}

:local .overlayStyle {
    transition: background-color 500ms linear, border-bottom 500ms linear;

    @media(min-width: $screen-sm) {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: $z-index-header;
        background-color: white;
    }
}

:local .overlayStyleTop {
    @media(min-width: $screen-sm) {
        background-color: transparent;
        border-bottom: none;
    }
}

:local .reviewFormPageHeader{
    background-color: white;
    text-align: center;
}