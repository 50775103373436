
:local .className {
    input[name="email"] {
        width: 300px;
        max-width: 100%;
        margin-bottom: 5px;
    }
    textarea {
        width: 100%;
        height: 150px; 
    }
}
:local .section {
    margin: 15px 0; 
}
  
:local .header {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px; 
}
  
:local .radio input[type="radio"] {
    margin-right: 5px; 
}
  
:local .helpText {
    margin-left: 5px;
    cursor: pointer; 
}
  
:local .subheader {
    font-size: 13px;
    color: #767b84;
    font-style: italic;
    margin-bottom: 5px; 
}
  
:local .error, 
:local .formError {
    color: #FF6161; 
}
  
  