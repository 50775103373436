@import "../../../scss/shared";;

:local {
  .checkoutCards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .checkoutCard {
    background: #FFFFFF;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08), 0 6px 12px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    width: 215px;
    height: 220px;
    overflow: hidden;
    margin-bottom: 24px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;

    .imageBlock {
      width: 100%;
      height: 132px;
      position: relative;
      background: #d1d3d8 url("../../../assets/images/rings.svg") no-repeat center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .pin {
      position: absolute;
      right: 8px;
      top: 8px;
      background: rgba(255, 255, 255, 0.9);
      border-radius: 10px;
      padding: 0 10px;
      line-height: 20px;
      font-size: 12px;
      color: #881155;
    }

    h3 {
      font-size: 12px;
      line-height: 15px;
      margin: 8px 0 0 8px;
      color: #24272B;
    }

    p {
      margin: 0 0 0 8px;
      color: #69717A;
      font-size: 11px;
      line-height: 16px;
    }

    .checkbox {
      margin: auto 0 8px 8px;

      div {
        width: 16px;
        height: 16px;
      }

      span {
        font-size: 12px;
        line-height: 16px;
        color: #1A2026;
        letter-spacing: 0.226139px;
      }
    }

    @media (max-width: $screen-xs-max) {
      width: calc(50% - 4px);
      height: 169px;

      .imageBlock {
        height: 81px;
      }
    }
  }
}