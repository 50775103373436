@import 'variables';
@import 'theme';
@import 'grid';

// start remove honeybook
$nx-breakpoint-medium: 768px;
$nx-breakpoint-large: 992px;
$nx-breakpoint-xlarge: 1200px;

$transparent-black: rgba(0,0,0,.5);

// mobile first queries
@mixin nx-medium-up {
    @media (min-width: $nx-breakpoint-medium) {
      @content;
    }
  }

  @mixin nx-large-up {
    @media (min-width: $nx-breakpoint-large) {
      @content;
    }
  }

  @mixin nx-xlarge-up {
    @media (min-width: $nx-breakpoint-xlarge) {
      @content;
    }
  }

  // specific screen queries
  @mixin nx-small-only {
    @media screen and (max-width: $nx-breakpoint-medium - 1) {
      @content;
    }
  }

  @mixin nx-medium-only {
    @media (min-width: $nx-breakpoint-medium) and
           (max-width: $nx-breakpoint-large - 1) {
      @content;
    }
  }

  @mixin nx-large-only {
    @media (min-width: $nx-breakpoint-large) and
           (max-width: $nx-breakpoint-xlarge - 1) {
      @content;
    }
  }

  // down queries
  @mixin nx-medium-down {
    @media (max-width: $nx-breakpoint-large - 1) {
      @content;
    }
  }

  @mixin nx-large-down {
    @media (max-width: $nx-breakpoint-xlarge - 1) {
      @content;
    }
  }
