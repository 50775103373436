:local {
    .list {
        display: flex;
        flex-wrap: wrap;
    }

    .slider {
        margin-top: 28px;
        height: 80px;
        position: relative;

    }

    .sliderContainer {
        width: 100%; // for unsupported browsers
        width: calc(100% + 25px);
        position: absolute;
        transform: translateX(-10px);
    }

    .sliderItem {
        padding-right: 8px;
    }

    .item {
        width: calc(50% - 4px);

        &:nth-child(2n - 1) {
            margin-right: 8px;
        }

        &__last {
            display: flex;
            position: relative;
            border-radius: 4px;
            overflow: hidden;
            height: 80px;
            border: 1px solid #D9DCDE;
            margin-bottom: 5px;

        }
    }

    .images {
        flex-wrap: wrap;
        display: flex;
        flex-shrink: 0;
        width: 80px;
        height: 80px;
    }

    .image {
        background: #d1d3d8 url("../../assets/images/rings.svg") no-repeat center;
        background-size: 75%;
        width: 40px;
        height: 40px;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .viewMore {
        flex: 1;
        height: 100%;
        background: #F2F2F2;
        display: flex;
        justify-content: center;
        padding-left: 16px;
        flex-direction: column;
        cursor: pointer;

        p {
            color: #881155;
            letter-spacing: 0;
            margin: 0;

            &:first-child {
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 23px;
                margin-bottom: 4px;
            }

            &:last-child {
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                text-align: left;
            }
        }
    }
}
