:local {
  .container {

  }

  .hotelsRecommendationsFormTitleBody {
    font-size: 14px;
    line-height: 17px;
    display: flex;
    flex-direction: column;
    color: #69717A;
    margin-bottom: 30px;
  }

  .chooseCardsWrapper {
    & > div:first-child {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 10px;
      & > div {
        width: auto;
        margin-right: 0;

        path {
          fill: white;
        }
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .venuesList {

    h2 {
      margin: 0 0 16px;
      font-size: 11px;
      line-height: 13px;
      color: #69717A;
      font-weight: bold;
    }

  }

  .hotelCards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & > div {
      width: calc(50% - 5px);
      background: white;
      margin-bottom: 16px;
      position: relative;
    }
  }
}