:local{

    .chatBubble {

        position: fixed;
        bottom: calc(2vh + 5px);
        right: 2vw;
        background-color: white;
        font-family: 'ProximaNova', sans-serif;
        box-shadow: 1px 1px 3px #49092e;
        border: 4px solid #881155;
        border-radius: 16px;
        z-index: 10;

        @media (max-width: 480px) {
            display: none !important;
        }

        &:before {
            content: " ";
            position: absolute;
            width: 0;
            height: 0;
            right: auto;
            top: auto;
            border-style: solid;
            left: 32px;
            bottom: -34px;
            border-color: #815 transparent transparent #815;
            border-width: 17px;
        }
        &:after {
            content: ' ';
            position: absolute;
            width: 0;
            height: 0;
            left: 37px;
            right: auto;
            top: auto;
            bottom: -21px;
            border: 12px solid;
            border-color: #fff transparent transparent #fff;
        }

        button {
            border: 1px solid #881155;
            box-sizing: border-box;
            border-radius: 4px;
            line-height: 17px;
            font-size: 14px;
            margin: 0 4px 0 0;
            padding-left: 30px;
            padding-right: 30px;
        }
    }

    .wrapper {
        padding: 1em;
        text-align: left;
        line-height: 20px;
    }
    .title {
        font-family: 'ProximaNova', sans-serif;
        font-size: 18px;
        margin: 0 0 16px 0;
        text-align: center;
        color: #69717A;
    }

    .content {
        display: flex;
        justify-content: center;
        padding: 10px 5px 5px 5px;
        align-items: baseline;
    }
}