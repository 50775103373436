:local {
  .wrapper {
    display: flex;
    flex-direction: column;
    padding: 22px;
    font-family: 'ProximaNova', sans-serif;
    .title {
      text-align: center;
      font-size: 14px;
      line-height: 20px;
      color: #69717A;
      margin-bottom: 25px;
    }
    .group {
      position:relative;
      margin-bottom:30px;

      &>input {
        font-size:18px;
        padding:10px 10px 10px 5px;
        display:block;
        width: 100%;
        border:none;
        border-bottom:1px solid #757575;

        &:required ~ .bar {
          &:after {
            content:'Required*';
            left: 0;
            top:6px;
            position: absolute;
            font-family: 'ProximaNova', sans-serif;
            font-size: 12px;
            display: flex;
            align-items: center;
            letter-spacing: 0.226139px;
            color: #69717A;
          }
        }

        &:focus ~ label, &:not(:optional):valid ~ label {
          top:-20px;
          font-size:14px;
          color:#881155;
        }

        &:-webkit-autofill ~ label {
          top:-20px;
          font-size:14px;
          color:#881155;
        }

        &:focus ~ .highlight {
          -webkit-animation:inputHighlighter 0.3s ease;
          -moz-animation:inputHighlighter 0.3s ease;
          animation:inputHighlighter 0.3s ease;
        }
      }

      input ~ label{
        color:#999;
        font-size:15px;
        font-weight:normal;
        position:absolute;
        pointer-events:none;
        top:20px;
        transition:0.2s ease all;
        -moz-transition:0.2s ease all;
        -webkit-transition:0.2s ease all;
      }

      &>textarea {
        width: 100%;
        height: 100px;
      }

      textarea ~ label {
        font-family: "ProximaNova", sans-serif;
        font-size: 14px;
        line-height: 16px;
        color: #999;
        top: -25px;
        position: absolute;
        }


      &>.highlight {
        position:absolute;
        height:60%;
        width:100px;
        top:25%;
        left:0;
        pointer-events:none;
        opacity:0.5;
      }
    }

    .bar  { position:relative; display:block; width:300px; }
    .bar:before, .bar:after   {
      content:'';
      height:2px;
      width:0;
      bottom:1px;
      position:absolute;
      background:#881155;
      transition:0.2s ease all;
      -moz-transition:0.2s ease all;
      -webkit-transition:0.2s ease all;
    }
    .bar:before {
      left:50%;
    }
    .bar:after {
      right:50%;
    }

    @-webkit-keyframes inputHighlighter {
      from { background:#881155; }
      to  { width:0; background:transparent; }
    }
    @-moz-keyframes inputHighlighter {
      from { background:#881155; }
      to  { width:0; background:transparent; }
    }
    @keyframes inputHighlighter {
      from { background:#881155; }
      to  { width:0; background:transparent; }
    }

    .formControls {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;

      button {
        box-shadow: 1px 1px 3px #49092e;
        border: 1px solid #881155;
        border-radius: 4px;
        z-index: 10;
        width: 336px;
        margin: 4px;
      }
    }

    .checkboxField {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 12px;
      & label {
        margin-left: 10px;
        line-height: 14px;
        letter-spacing: 0.226139px;
      }
      input[type=checkbox] {
        position: relative;
        cursor: pointer;
        &:before{
          content: "";
          display: block;
          position: absolute;
          width: 21px;
          height: 21px;
          top: 0;
          left: 0;
          border: 2px solid #881155;
          border-radius: 3px;
          background-color: white;
        }
        &:checked:before {
          content: "";
          display: block;
          border: solid white;
          background-color: #881155;
          border-width: 0 2px 2px 0;
          position: absolute;
          width: 21px;
          height: 21px;
          top: 0;
          left: 0;
        }
        &:checked:after {
          content: "";
          display: block;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          position: absolute;
          top: 2px;
          left: 6px;
        }
      }
    }
  }
}