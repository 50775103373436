@import '../../scss/shared.scss';

.App__VenuePage .App {
    @include nx-small-only {
        // Add some padding to account for the sticky footer in venue profile page
        .Footer--container {
            padding-bottom: 140px;
        }
    }
}

:local .className {
    background-image: url('../../assets/images/footer/bg.png');
}

:local .container {
    @include clearfix;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 20px 0;
}

:local .logoWrapper {
    text-align: center;
}

:local .trademarks {
    margin-top: 10px;
    text-align: center;
}

:local .cventLogo {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    > img {
        width: 83px;
    }
}

:local .trademarkSeparator {
    vertical-align: middle;
    display: inline-block;
    font-size: 25px;
    color: #aaa;
    margin: 7px 5px 0px 10px;
}

:local .wsLogo,
:local .hbLogo {
    display: inline-block;
    vertical-align: middle;
    > img {
        width: 85px;
    }
}

:local .hbLogo {
    margin-top: 5px;
}

:local .copyright {
    display: block;
    padding: 5px 0;
    text-align: center;
    font-size: 12px;
    color: #666;
}

:local .termsLinks {
    padding-top: 5px;
    text-align: center;
    > a {
        display: inline-block;
        color: #888;
        font-size: 12px;

        &:hover {
            color: #881155;
        }
    }
}

:local .termsLinksSeparator {
    color: #000;
    display: inline-block;
    padding: 0 5px;
    vertical-align: middle;
}

:local .socialWrapper {
    @include clearfix;
    margin-top: 12px;
    text-align: center;
}

:local .socialLink {
    display: inline-block;
    > img {
        width: 35px;
    }
}

:local .nav {
    @include clearfix;

    > ul {
        width: 20%;
        float: left;
        list-style: none;
        > li > a {
            color: #888;
            font-size: 12px;
            line-height: 20px;
            &:hover {
                color: #881155;
            }
        }
    }
}

:local .headline {
    color: #666;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
}

@include nx-small-only {
    :local .nav {
        display: flex;
        flex-direction: column;

        > ul {
            width: 100%;
            margin: 10px 0 0 0;

            > li:not(.headline) {
                display: inline-block;
                margin-right: 20px;
            }

            > li.fullWidth {
                width: 100%;
                margin-right: 0;
            }

            > li.headline {
                margin: 0 0 5px 0;
            }
        }
    }
    :local .container {
        width: auto;
        display: flex;
        flex-direction: column-reverse;
    }
    :local .trademarks {
        width: 100%;
    }
    :local .hiddenMobile {
        display: none;
    }
}
