@import '../../../scss/shared.scss';
:local {
  .subHeaderMessage {
    color: $color-text-light;
    text-align: center;
    margin: 10px 0 20px;
    font-size: 17px;
  }

  .exploreHotelsCTA {
    display: block;
    margin: 20px;
    cursor: pointer;
    text-align: center;
    color: $color-brand-primary;

    &:hover,
    &:focus {
      $link-hover-color: $link-color;
    }
  }
  
  .body {
    & > div:first-child {
      justify-content: space-between;
      & > div {
        width: 32%;
        margin-right: 0;

        path {
          fill: white;
        }
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
}