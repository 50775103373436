:local .ShoppingCartInquiry {
  :global {
    .InquiryForm--attribution-logo {
      @media (min-width: 768px) {
        float: left;
        padding: 0;
      }
    }

    .InquiryForm--radio-row {
      & > div {
        margin-top: 41px;
        display: flex;

        .InquiryForm--inline-radio-lbl {
          display: flex;
          justify-content: center;
          margin-left: 21px;

          input {
            margin-right: 8px;
            margin-top: 1px;
          }
        }
      }
    }
  }
}

:local .responsiveContainer {
  @media (max-width: 768px) {
    overflow: auto;
  }

  :global(.ResponsiveModal--content) {
    @media (max-width: 768px) {
      overflow: auto;
    }
  }
}
